<template>
    <v-card class="mx-auto" elevation="2">
        <v-card-text>
            <v-form>
                <v-row>
                    <v-col>
                        <label>Card Holder Name</label>
                        <v-text-field dense outlined id="card-holder-name" type="text" v-model="name" class="form-control mb-2" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div id="card-element">

                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-progress-linear v-if="loading || !ready" indeterminate/>
                    </v-col>
                </v-row>
                <v-row v-if="error">
                    <v-col>
                        <v-alert type="error">
                            {{ error.message }}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions v-if="ready && !loading">
            <v-spacer/>
            <v-btn id="add-card-button" @click="handleSubmit">
                Submit
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import StripeApi from "../api/StripeApi";
    import BillingApi from "../api/BillingApi";

    export default {
        name: "StripeCheckoutCard",
        props: {
            loading: {
                type: Boolean,
                default: () => false
            }
        },
        data: function () {
            return {
                ready: false,
                publishableKey: process.env.VUE_APP_STRIPE_KEY,
                stripe: '',
                elements: '',
                card: '',
                intentToken: '',
                name: '',
                error: null
            }
        },
        methods: {
            async handleSubmit() {
                this.$emit('submit-start')
                const payload = {payment_method: {card: this.card, billing_details: {name: this.name}}}
                const result = await this.stripe.confirmCardSetup(this.intentToken.client_secret, payload)

                if (result.error) {
                    this.error = result.error
                    this.$emit('submit-error', this.error)
                    return
                }

                this.card.clear();
                this.name = '';
                this.$emit('submit-card', result.setupIntent.payment_method)
            }
        },
        async mounted() {
            this.intentToken = await BillingApi.loadIntentToken();
            StripeApi.includeStripe(async () => {
                this.stripe = await window.Stripe(this.publishableKey);
                this.elements = this.stripe.elements();
                this.card = this.elements.create('card');
                await this.card.mount('#card-element');
                this.ready = true
            });
        }
    }
</script>

<style scoped>

</style>
