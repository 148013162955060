<template>
    <dashboard-layout>
        <v-row>
            <v-col>
                <v-card class="mx-auto px-15 py-15" flat outlined max-width="800">
                    <v-card-title>You're subscribing to {{ selectedPlan.name }}</v-card-title>
                    <v-card-text>
                        <stripe-checkout-card
                            :loading="paymentLoading"
                            @submit-start="paymentLoading = true"
                            @submit-error="paymentLoading = false"
                            @submit-card="subscribe"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import BillingApi from "@/api/BillingApi";
import StripeCheckoutCard from "@/components/StripeCheckoutCard";
import {mapMutations} from "vuex";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "Checkout",
    components: {
        DashboardLayout,
        StripeCheckoutCard
    },
    data: function () {
        return {
            addPaymentStatusError: '',
            selectedPlan: {},
            paymentLoading: false,
        }
    },
    methods: {
        subscribe(method) {
            BillingApi.updateSubscription(method, this.selectedPlan.stripe_id).then(() => {
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Subscription confirmed'})
                this.paymentLoading = false
                this.$auth.fetch()
            })
        },
        ...mapMutations(['showSnackBar'])
    },
    async mounted() {
        if (this.$route.params.plan) {
            this.selectedPlan = this.$route.params.plan;
            return
        }

        this.$router.push({name: 'dashboard.billing.select-plan'})
    },
}
</script>

<style scoped>

</style>
